<template>
  <b-tabs>
    <b-tab title="Service">

      <!-- Thumbnail Section -->
      <b-card no-body v-if="$parent.moduleRole('home_thumbnail')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Home Thumbnail</h5>
            <b-button type="button" variant="outline-info" class="btn-rounded" v-if="!contentUpdating.thumbnail" @click="setUpdatingThumbnail(true)">
              <span>Update Content</span>
              <i class="fas fa-pencil-alt ml-2"></i>
            </b-button>
            <b-button type="button" variant="outline-danger" class="btn-rounded" v-else @click="setUpdatingThumbnail(false)">
              <span>Cancel</span>
              <i class="fas fa-times ml-2"></i>
            </b-button>
          </div>
        </template>
        <template v-if="contentUpdating.thumbnail">
          <validation-observer ref="VFormThumbnail" v-slot="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitThumbnail)">
              <b-card-body>
                <b-row>
                  <b-col md="5">
                    <b-form-group class="file-uploader">
                      <template #label>Service Animation <span class="text-danger">*</span></template>
                      <div v-if="dataModalThumb.as_thumb_file">
                        <div class="file-uploader-media lplayer-wrapper">
                          <lottie-vue-player :src="$parent.uploaderJson(dataModalThumb.as_thumb_file)" autoplay loop />
                        </div>
                      </div>
                      <Uploader v-model="dataModalThumb.as_thumb_file" type="lottieplayer" :no-preview="true" />
                      <VValidate name="Service Animation" v-model="dataModalThumb.as_thumb_file" :rules="mrValidationThumbnail.as_thumb_file" />
                    </b-form-group>
                  </b-col>
                  <b-col md="7">
                    <b-form-group>
                      <template #label>Service Title <span class="text-danger">*</span></template>
                      <b-form-input v-model="dataModalThumb.as_title" placeholder="e.g. Software Development"></b-form-input>
                      <VValidate name="Service Title" v-model="dataModalThumb.as_title" :rules="mrValidationTitle.as_title" />
                    </b-form-group>
                    <b-form-group>
                      <template #label>Description (ID) <span class="text-danger">*</span> </template>
                      <b-form-textarea v-model="dataModalThumb.as_thumb_desc_id" :rows="4" placeholder="e.g. Memahami kebutuhan Anda sangatlah esensial..."></b-form-textarea>
                      <VValidate name="Description (ID)" v-model="dataModalThumb.as_thumb_desc_id" :rules="mrValidationThumbnail.as_thumb_desc_id" />
                    </b-form-group>
                    <b-form-group>
                      <template #label>Description (EN) <span class="text-danger">*</span> </template>
                      <b-form-textarea v-model="dataModalThumb.as_thumb_desc_en" placeholder="e.g. Understanding your needs deeply is essential to..." :rows="4"></b-form-textarea>
                      <VValidate name="Description (EN)" v-model="dataModalThumb.as_thumb_desc_en" :rules="mrValidationThumbnail.as_thumb_desc_en" />
                    </b-form-group>                    
                    <b-form-group>
                      <template #label>Background Color <span class="text-danger">*</span> </template>
                      <chrome-picker :value="dataModalThumb.as_thumb_bg_color" @input="updateColor($event, dataModalThumb, 'as_thumb_bg_color')" />
                      <VValidate name="Background Color" v-model="dataModalThumb.as_thumb_bg_color" :rules="mrValidationThumbnail.as_thumb_bg_color" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button type="button" variant="transparent" @click="setUpdatingThumbnail(false)">Cancel</b-button>
                  <b-button type="submit" variant="success" class="btn-rounded">
                    <i class="fas fa-check mr-2"></i><span>Save Changes</span>
                  </b-button>
                </div>
              </b-card-footer>
            </b-form>
          </validation-observer>
        </template>
        <template v-else>
          <div class="serv-home-thumbnail" :style="'background-color:' + row.as_thumb_bg_color">
            <div class="sht-content p-5">
              <b-row align-v="center">
                <b-col md="6">
                  <div>
                    <div class="lplayer-wrapper" v-if="row.as_thumb_file">
                      <lottie-vue-player :src="JSON.stringify($parent.uploaderJson(row.as_thumb_file))" autoplay loop style="background-color: transparent" />
                    </div>
                  </div>
                </b-col>
                <b-col md="6">
                  <div>
                    <h3>{{row.as_title}}</h3>
                    <p>{{row.as_thumb_desc_id}}</p>
                    <b-row class="g-1">
                      <b-col md="6" v-for="(value, index) in row.as_sub_content" :key="index">
                        <div class="d-flex align-items-center"><i class="fas fa-check mr-2"></i><span>{{value.name_id}}</span></div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </b-card>
      <!-- / Thumbnail Section -->

      <!-- Hero Section -->
      <b-card no-body v-if="$parent.moduleRole('hero')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Hero Image</h5>
            <b-button type="button" variant="outline-info" class="btn-rounded" v-if="!contentUpdating.hero" @click="setUpdatingHero(true)">
              <span>Update Content</span>
              <i class="fas fa-pencil-alt ml-2"></i>
            </b-button>
            <b-button type="button" variant="outline-danger" class="btn-rounded" v-else @click="setUpdatingHero(false)">
              <span>Cancel</span>
              <i class="fas fa-times ml-2"></i>
            </b-button>
          </div>
        </template>
        <template v-if="contentUpdating.hero">
          <validation-observer ref="VFormHero" v-slot="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitHero)">
              <b-card-body>
                <b-row>
                  <b-col md="7">
                    <b-form-group>
                      <template #label>Title (ID) <span class="text-danger">*</span></template>
                      <b-form-input v-model="dataModalHero.as_hero_title_id" placeholder="e.g. Mulai dari ide, akan kami bentuk menjadi aplikasi nan produktif."></b-form-input>
                      <VValidate name="Title (ID)" v-model="dataModalHero.as_hero_title_id" :rules="mrValidationHero.as_hero_title_id" />
                    </b-form-group>
                    <b-form-group>
                      <template #label>Title (EN) <span class="text-danger">*</span></template>
                      <b-form-input v-model="dataModalHero.as_hero_title_en" placeholder="e.g. Just start your small idea, let us shape it into a productive application."></b-form-input>
                      <VValidate name="Title (EN)" v-model="dataModalHero.as_hero_title_en" :rules="mrValidationHero.as_hero_title_en" />
                    </b-form-group>
                    <b-form-group>
                      <template #label>Description (ID) <span class="text-danger">*</span></template>
                      <b-form-textarea :rows="4" v-model="dataModalHero.as_hero_desc_id" placeholder="e.g. Memahami kebutuhan Anda sangatlah esensial..."></b-form-textarea>
                      <VValidate name="Description (ID)" v-model="dataModalHero.as_hero_desc_id" :rules="mrValidationHero.as_hero_desc_id" />
                    </b-form-group>
                    <b-form-group>
                      <template #label>Description (EN) <span class="text-danger">*</span></template>
                      <b-form-textarea :rows="4" v-model="dataModalHero.as_hero_desc_en" placeholder="e.g. Understanding your needs deeply is essential to..."></b-form-textarea>
                      <VValidate name="Description (EN)" v-model="dataModalHero.as_hero_desc_en" :rules="mrValidationHero.as_hero_desc_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group>
                      <template #label>Hero Image <span class="text-danger">*</span></template>
                      <Uploader v-model="dataModalHero.as_hero_image" type="hero_service" />
                      <VValidate name="Hero Image" v-model="dataModalHero.as_hero_image" :rules="mrValidationHero.as_hero_image" />
                    </b-form-group>
                    <b-form-group class="mb-0" label-for="GradientColor">
                      <template #label="">Hero Gradient Background</template>
                      <div class="d-flex align-items-center mb-3">
                        <material-picker :value="dataModalHero.as_hero_gradient_left" @input="updateColor($event, dataModalHero, 'as_hero_gradient_left')" />
                        <div class="p-2"></div>
                        <material-picker :value="dataModalHero.as_hero_gradient_left" @input="updateColor($event, dataModalHero, 'as_hero_gradient_right')" />
                      </div>
                      <div class="px-3 py-4" :style="'background-image: linear-gradient(90deg, '+dataModalHero.as_hero_gradient_left+' 0%, '+dataModalHero.as_hero_gradient_right+' 109.76%)'"></div>
                      <VValidate name="Hero Gradient Left" v-model="dataModalHero.as_hero_gradient_left" :rules="mrValidationHero.as_hero_gradient_left" />
                      <VValidate name="Hero Gradient Right" v-model="dataModalHero.as_hero_gradient_right" :rules="mrValidationHero.as_hero_gradient_right" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button type="button" variant="transparent" @click="setUpdatingHero(false)">Cancel</b-button>
                  <b-button type="submit" variant="success" class="btn-rounded">
                    <i class="fas fa-check mr-2"></i><span>Save Changes</span>
                  </b-button>
                </div>
              </b-card-footer>
            </b-form>
          </validation-observer>
        </template>
        <template v-else>
          <div class="hero-image-preview">
            <div class="hip-content" :style="'--vbg-stop-1: '+row.as_hero_gradient_left+';--vbg-stop-2: '+row.as_hero_gradient_right+';'">
              <div class="hi-main d-flex flex-column justify-content-center p-5 h-100">
                <b-row align-h="between">
                  <b-col md="6" align-self="center">
                    <h3>{{row.as_hero_title_id}}</h3>
                    <span>{{row.as_hero_desc_id}}</span>
                  </b-col>
                  <b-col md="6" lg="5">
                    <div>
                      <div class="position-relative d-flex flex-colum align-items-center">
                        <div class="serv-border-wrapper">
                          <div class="position-relative w-100 h-100">
                            <div class="service-main-border serv-img-rounder"></div>
                          </div>
                        </div>
                        <div class="service-main-img position-relative">
                          <img :src="$parent.uploader(row.as_hero_image)" class="serv-img-rounder img-responsive" alt="">
                        </div>
                      </div>
                      <div class="service-main-decoration pb-40">
                        <div class="smd smd-1 pb-60">
                          <svg width="141" height="117" viewBox="0 0 141 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1069_884)">
                              <circle cx="58.5" cy="58.5" r="58.5" fill="url(#paint0_linear_1069_884)" />
                              <path d="M59 62L67.2 54L75.4 62L83.6 54L91.8 62L100 54L108.2 62L116.4 54L124.6 62L132.8 54L141 62"
                                stroke="white" stroke-width="2" />
                            </g>
                            <defs>
                              <linearGradient id="paint0_linear_1069_884" x1="58.5" y1="0" x2="58.5" y2="117"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4297FB" />
                                <stop offset="1" stop-color="#4297FB" stop-opacity="0" />
                              </linearGradient>
                              <clipPath id="clip0_1069_884">
                                <rect width="141" height="117" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div class="smd smd-2">
                          <svg width="72" height="74" viewBox="0 0 72 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.880041 18.1897C-0.89087 6.77926 10.3544 -2.26933 21.1216 1.90222L61.5919 17.5818C72.3591 21.7533 74.5728 36.0163 65.5765 43.2552L31.7625 70.4638C22.7662 77.7026 9.30725 72.4882 7.53634 61.0778L0.880041 18.1897Z" fill="#FFA625"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </template>
      </b-card>
      <!-- / Hero Section -->      

      <!-- Sub Services -->
      <b-card no-body v-if="$parent.moduleRole('sub_service')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Service & Sub-Services Information</h5>
            <b-form-checkbox
              id="showSub"
              v-model="row.as_show_sub"
              @change="showSection('as_show_sub', $event)"
              name="showSub"
              value="Y"
              unchecked-value="N"
            >
              Show Section?
            </b-form-checkbox>
          </div>
        </template>
        <b-card-body>
          <div class="wrap_title_desc mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{row.as_sub_name_id}}</h4>
              <b-button type="button"
                variant="outline-info"
                class="btn-rounded ml-auto"
                size="sm"
                @click="openModalSubTitle"
              > Update Content <i class="fas fa-pencil-alt ml-2"></i></b-button>
            </div>
            <p>{{row.as_sub_desc_id}}</p>
          </div>
        </b-card-body>
        <b-table-simple hover>
          <b-thead>
            <b-tr>
              <b-th>#</b-th>
              <b-th width="200px">Sub Service Name</b-th>
              <b-th width="400px">Description</b-th>
              <b-th>Image</b-th>
              <b-th>Status</b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(value, index) in row.as_sub_content" :key="index">
              <b-td>{{ index + 1 }}</b-td>
              <b-td>{{ value.name_id }}</b-td>
              <b-td>{{ value.desc_id }}</b-td>
              <b-td>
                <p class="mb-0" v-if="value.is_svg == 'Y'" v-html="value.svg_tag"></p>
                <img :src="$parent.uploader(value.image)" v-else />
              </b-td>
              <b-td>
                <b-badge v-if="value.is_active == 'Y'" variant="success">Active</b-badge>
                <b-badge v-else variant="danger">Inactive</b-badge>
              </b-td>
              <b-td>
                <a href="javascript:;" class="btn btn-icon btn-secondary rounded-pill" @click.prevent="openModalSubService(value)">
                  <i class="fas fa-pencil-alt"></i>
                </a>
                <button type="button" class="btn btn-icon btn-danger rounded-pill" @click.prevent="doDelete('as_sub_content', value.id)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </b-td>
            </b-tr>
          </b-tbody>
          <tfoot>
            <b-tr>
              <b-th colspan="6" class="text-center">
                <b-button type="button" variant="outline-success" class="btn-rounded" @click="openModalSubService({is_new: true, is_active: 'Y'})"><i class="fa fa-plus-circle mr-2"></i>Add What We Do </b-button>
              </b-th>
            </b-tr>
          </tfoot>
        </b-table-simple>
      </b-card>
      <!-- / Sub Services -->

      <!-- Service Collapse -->
      <b-card no-body v-if="$parent.moduleRole('service_collapse')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Service Collapse Information</h5>
            <b-form-checkbox
              id="showCollapse"
              v-model="row.as_show_service_collapse"
              @change="showSection('as_show_service_collapse', $event)"
              name="showCollapse"
              value="Y"
              unchecked-value="N"
            >
              Show Section?
            </b-form-checkbox>
          </div>
        </template>
        <b-card-body>
          <div class="wrap_title_desc mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{row.as_service_col_name_id}}</h4>
              <b-button type="button"
                variant="outline-info"
                class="btn-rounded ml-auto"
                size="sm"
                @click="openModalServiceCollapseTitle"
              > Update Content <i class="fas fa-pencil-alt ml-2"></i></b-button>
            </div>
            <p>{{row.as_service_col_desc_id}}</p>
          </div>
        </b-card-body>
        <b-table-simple hover>
          <b-thead>
            <b-tr>
              <b-th>#</b-th>
              <b-th width="200px">Title</b-th>
              <b-th width="400px">Content</b-th>
              <b-th>Status</b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(value, index) in row.as_service_col_content" :key="index">
              <b-td>{{ index + 1 }}</b-td>
              <b-td>{{ value.title_id }}</b-td>
              <b-td>{{ value.desc_id }}</b-td>
              <b-td>
                <b-badge v-if="value.is_active == 'Y'" variant="success">Active</b-badge>
                <b-badge v-else variant="danger">Inactive</b-badge>
              </b-td>
              <b-td>
                <a href="javascript:;" class="btn btn-icon btn-secondary rounded-pill" @click.prevent="openModalServiceCollapse(value)">
                  <i class="fas fa-pencil-alt"></i>
                </a>
                <button type="button" class="btn btn-icon btn-danger rounded-pill" @click.prevent="doDelete('as_service_col_content', value.id)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </b-td>
            </b-tr>
          </b-tbody>
          <tfoot>
            <b-tr>
              <b-th colspan="6" class="text-center">
                <b-button type="button" variant="outline-success" class="btn-rounded" @click="openModalServiceCollapse({is_new: true, is_active: 'Y'})"><i class="fa fa-plus-circle mr-2"></i>Add Collapse Content </b-button>
              </b-th>
            </b-tr>
          </tfoot>
        </b-table-simple>
      </b-card>
      <!-- / Service Collapse -->

      <!-- Service Process -->
      <b-card no-body v-if="$parent.moduleRole('service_process')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Service Process & Methodology</h5>
            <b-form-checkbox
              id="showProcess"
              v-model="row.as_show_service_process"
              @change="showSection('as_show_service_process', $event)"
              name="showProcess"
              value="Y"
              unchecked-value="N"
            >
              Show Section?
            </b-form-checkbox>
          </div>
        </template>
        <b-card-body>
          <div class="wrap_title_desc mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{row.as_service_process_name_id}}</h4>
              <b-button type="button"
                variant="outline-info"
                class="btn-rounded ml-auto"
                size="sm"
                @click="openModalServiceProcessTitle"
              > Update Content <i class="fas fa-pencil-alt ml-2"></i></b-button>
            </div>
            <p>{{row.as_service_process_desc_id}}</p>
          </div>
        </b-card-body>
        <b-card-body class="border-top">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="side-title">
              <h6 class="mb-0">Process & Methodology Content</h6>
              <small>Content Type: Numbered Step with Image</small>
            </div>
          </div>
          <div class="form-group">
            <label for="">Content Type <strong class="text-danger">*</strong></label>
            <b-row>
              <b-col md="4">
                <v-select :options="mrServiceProcessType" @input="showSection('as_service_process_type', $event)" v-model="row.as_service_process_type" :clearable="false" :reduce="data => data.value"/>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
        <b-card-body>
          <b-row>
            <b-col lg="6" v-for="(value, index) in row.as_service_process_content" :key="index">
              <article class="service_list" style="min-height:auto;">
                <div class="bulle_cta">                  
                  <b-button variant="secondary" class="btn-icon" type="button" pill @click="openModalServiceProcess(value)">
                    <i class="fas fa-pencil-alt" />
                  </b-button>
                  <b-button variant="danger" class="btn-icon" pill @click="doDelete('as_service_process_content', value.id)">
                    <i class="far fa-trash-alt" />
                  </b-button>
                </div>
                <b-row class="gutter-2">
                  <b-col lg="3">
                    <div class="dev-process-tabs" v-if="value.is_svg == 'Y'" :style="`--pc-nav-bg: ${value.color||'#000'}`"> 
                      <div class="pc-nav-icon d-flex flex-column text-center justify-content-center align-items-center" style="transform: translateY(100%)">
                        <p class="mb-0" v-if="value.is_svg == 'Y'" v-html="value.svg_tag"></p>
                      </div>
                    </div>
                    <img :src="$parent.uploader(value.image)" v-else />                    
                  </b-col>
                  <b-col lg="8">
                    <h2>{{value.title_id}}</h2>
                    <p>{{value.desc_id}}</p>

                    <p class="text-center">
                      <b-badge variant="success" v-if="value.is_active == 'Y'">Active</b-badge>
                      <b-badge variant="danger" v-else>Inactive</b-badge>
                    </p>
                  </b-col>
                </b-row>
              </article>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=12 class="justify-content-center text-center">
              <b-button type="button" variant="outline-success" class="btn-rounded" @click="openModalServiceProcess({is_new: true, is_active: 'Y'})"><i class="fa fa-plus-circle mr-2"></i>Add Process </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <!-- / Service Process -->

      <!-- Tech Stack -->
      <b-card no-body v-if="$parent.moduleRole('tech_stack')">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Technology We Work With</h5>
            <b-form-checkbox
              id="showTech"
              v-model="row.as_show_service_tech"
              @change="showSection('as_show_service_tech', $event)"
              name="showTech"
              value="Y"
              unchecked-value="N"
            >
              Show Section?
            </b-form-checkbox>
          </div>
        </template>
        <b-card-body>
          <div class="wrap_title_desc mb-0">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{row.as_service_tech_name_id}}</h4>
              <b-button type="button"
                variant="outline-info"
                class="btn-rounded ml-auto"
                size="sm"
                @click="openModalServiceTechTitle"
              > Update Content <i class="fas fa-pencil-alt ml-2"></i></b-button>
            </div>
            <p>{{row.as_service_tech_desc_id}}</p>
          </div>
        </b-card-body>
        <b-tabs>
          <b-tab :title="category" v-for="(category, key) in categoryService" :key="`cat-${key}`" nav-class="nav-tabs">
            <b-table-simple hover>
              <b-thead>
                <b-tr>
                  <b-th>#</b-th>
                  <b-th>Category Name	</b-th>
                  <b-th>Name</b-th>
                  <b-th>Image</b-th>
                  <b-th>Status</b-th>
                  <b-th>Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(value, index) in techContent(category)" :key="index">
                  <b-td>{{ index + 1 }}</b-td>
                  <b-td>{{ value.category }}</b-td>
                  <b-td>{{ value.name }}</b-td>
                  <b-td>
                    <img :src="$parent.uploader(value.image)" width="80" />
                  </b-td>
                  <b-td>
                    <b-badge v-if="value.is_active == 'Y'" variant="success">Active</b-badge>
                    <b-badge v-else variant="danger">Inactive</b-badge>
                  </b-td>
                  <b-td>
                    <a href="javascript:;" class="btn btn-icon btn-secondary rounded-pill" @click.prevent="openModalServiceTech(value)">
                      <i class="fas fa-pencil-alt"></i>
                    </a>
                    <button type="button" class="btn btn-icon btn-danger rounded-pill" @click="doDelete('as_service_tech_content', value.id)">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </b-td>
                </b-tr>
              </b-tbody>
              <tfoot>
                <b-tr>
                  <b-th colspan="6" class="text-center">
                    <b-button type="button" variant="outline-success" class="btn-rounded" @click="openModalServiceTech({is_new: true, is_active: 'Y'})"><i class="fa fa-plus-circle mr-2"></i>Add Technology Stack </b-button>
                  </b-th>
                </b-tr>
              </tfoot>
            </b-table-simple>
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- / Service Tech Stack -->

      <!-- Modal Sub Service Title -->
      <b-modal id="modal_sub_service_title" no-close-on-backdrop title="Update Service Information">
        <validation-observer ref="VFormSubServiceTitle" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitSubServiceTitle)">
            <b-form-group>
              <template #label>Service Name (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalSubServiceTitle.as_sub_name_id" placeholder="e.g. Pengembangan Perangkat Lunak"></b-form-input>
              <VValidate name="Service Name (ID)" v-model="dataModalSubServiceTitle.as_sub_name_id" :rules="mrValidationSubServiceTitle.as_sub_name_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Name (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalSubServiceTitle.as_sub_name_en" placeholder="e.g. Software Development"></b-form-input>
              <VValidate name="Service Name (EN)" v-model="dataModalSubServiceTitle.as_sub_name_en" :rules="mrValidationSubServiceTitle.as_sub_name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalSubServiceTitle.as_sub_desc_id"></b-form-textarea>
              <VValidate name="Service Description (ID)" v-model="dataModalSubServiceTitle.as_sub_desc_id" :rules="mrValidationSubServiceTitle.as_sub_desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalSubServiceTitle.as_sub_desc_en"></b-form-textarea>
              <VValidate name="Service Description (EN)" v-model="dataModalSubServiceTitle.as_sub_desc_en" :rules="mrValidationSubServiceTitle.as_sub_desc_en" />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_sub_service_title')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitSubServiceTitle">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Sub Service Title -->

      <!-- Modal Sub Service -->
      <b-modal id="modal_sub_service" no-close-on-backdrop title="Form Sub Service">
        <validation-observer ref="VFormSubService" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitSubService)">
            <b-form-group>
              <b-form-radio-group
                v-model="dataModalSubService.is_svg"
                :options="optionsSVG"
                button-variant="outline-primary"
                buttons
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group v-if="dataModalSubService.is_svg == 'Y'">
              <p class="text-center" v-html="dataModalSubService.svg_tag"></p>

              <template #label>SVG Tag <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalSubService.svg_tag"></b-form-textarea>
              <VValidate name="SVG Tag" v-model="dataModalSubService.svg_tag" :rules="{ required: 1}" />
            </b-form-group>

            <b-form-group v-else>
              <template #label>Image <span class="text-danger">*</span></template>
              <Uploader v-model="dataModalSubService.image" type="sub_service" />
              <VValidate name="Image" v-model="dataModalSubService.image" :rules="{ required: 1 }" />
            </b-form-group>
            <b-form-group>
              <template #label>Background Color <span class="text-danger">*</span></template>              
              <chrome-picker  :value="dataModalSubService.color" @input="updateColor($event, dataModalSubService, 'color')" />
              <VValidate name="Background Color" v-model="dataModalSubService.color" :rules="mrValidationSubService.color" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Name (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalSubService.name_id" placeholder="e.g. Pengembangan Perangkat Lunak"></b-form-input>
              <VValidate name="Service Name (ID)" v-model="dataModalSubService.name_id" :rules="mrValidationSubService.name_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Name (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalSubService.name_en" placeholder="e.g. Software Development"></b-form-input>
              <VValidate name="Service Name (EN)" v-model="dataModalSubService.name_en" :rules="mrValidationSubService.name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalSubService.desc_id"></b-form-textarea>
              <VValidate name="Service Description (ID)" v-model="dataModalSubService.desc_id" :rules="mrValidationSubService.desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Service Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalSubService.desc_en"></b-form-textarea>
              <VValidate name="Service Description (EN)" v-model="dataModalSubService.desc_en" :rules="mrValidationSubService.desc_en" />
            </b-form-group>
            <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="$parent.Config.mr.StatusOptions"
                v-model="dataModalSubService.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalSubService.is_active" 
                :rules="mrValidationSubService.is_active" 
              />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_sub_service')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitSubService">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Sub Service -->

      <!-- Modal Service Collapse Title -->
      <b-modal id="modal_service_collapse_title" no-close-on-backdrop title="Update Service Collapse">
        <validation-observer ref="VFormServiceCollapseTitle" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceCollapseTitle)">
            <b-form-group>
              <template #label>Name (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceCollapseTitle.as_service_col_name_id" placeholder="e.g. Pengembangan Perangkat Lunak"></b-form-input>
              <VValidate name="Name (ID)" v-model="dataModalServiceCollapseTitle.as_service_col_name_id" :rules="mrValidationServiceCollapseTitle.as_service_col_name_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Name (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceCollapseTitle.as_service_col_name_en" placeholder="e.g. Software Development"></b-form-input>
              <VValidate name="Name (EN)" v-model="dataModalServiceCollapseTitle.as_service_col_name_en" :rules="mrValidationServiceCollapseTitle.as_service_col_name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceCollapseTitle.as_service_col_desc_id"></b-form-textarea>
              <VValidate name="Description (ID)" v-model="dataModalServiceCollapseTitle.as_service_col_desc_id" :rules="mrValidationServiceCollapseTitle.as_service_col_desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceCollapseTitle.as_service_col_desc_en"></b-form-textarea>
              <VValidate name="Description (EN)" v-model="dataModalServiceCollapseTitle.as_service_col_desc_en" :rules="mrValidationServiceCollapseTitle.as_service_col_desc_en" />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_collapse_title')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceCollapseTitle">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Collapse Title -->

      <!-- Modal Service Collapse -->
      <b-modal id="modal_service_collapse" no-close-on-backdrop title="Form Service Collapse">
        <validation-observer ref="VFormServiceCollapse" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceCollapse)">
            <b-form-group>
              <template #label>Title (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceCollapse.title_id" placeholder="e.g. OWASP"></b-form-input>
              <VValidate name="Title (ID)" v-model="dataModalServiceCollapse.title_id" :rules="mrValidationServiceCollapse.title_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Title (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceCollapse.title_en" placeholder="e.g. OWASP"></b-form-input>
              <VValidate name="Title (EN)" v-model="dataModalServiceCollapse.title_en" :rules="mrValidationServiceCollapse.name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceCollapse.desc_id"></b-form-textarea>
              <VValidate name="Description (ID)" v-model="dataModalServiceCollapse.desc_id" :rules="mrValidationServiceCollapse.desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceCollapse.desc_en"></b-form-textarea>
              <VValidate name="Description (EN)" v-model="dataModalServiceCollapse.desc_en" :rules="mrValidationServiceCollapse.desc_en" />
            </b-form-group>
            <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="$parent.Config.mr.StatusOptions"
                v-model="dataModalServiceCollapse.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalServiceCollapse.is_active" 
                :rules="mrValidationServiceCollapse.is_active" 
              />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_collapse')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceCollapse">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Collapse -->

      <!-- Modal Service Process Title -->
      <b-modal id="modal_service_process_title" no-close-on-backdrop title="Update Service Process & Methodology">
        <validation-observer ref="VFormServiceProcessTitle" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceProcessTitle)">
            <b-form-group>
              <template #label>Name (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceProcessTitle.as_service_process_name_id" placeholder="e.g. Proses & Metodologi Pengembangan"></b-form-input>
              <VValidate name="Name (ID)" v-model="dataModalServiceProcessTitle.as_service_process_name_id" :rules="mrValidationServiceProcessTitle.as_service_process_name_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Name (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceProcessTitle.as_service_process_name_en" placeholder="e.g. Development Process & Methodology"></b-form-input>
              <VValidate name="Name (EN)" v-model="dataModalServiceProcessTitle.as_service_process_name_en" :rules="mrValidationServiceProcessTitle.as_service_process_name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceProcessTitle.as_service_process_desc_id"></b-form-textarea>
              <VValidate name="Description (ID)" v-model="dataModalServiceProcessTitle.as_service_process_desc_id" :rules="mrValidationServiceProcessTitle.as_service_process_desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceProcessTitle.as_service_process_desc_en"></b-form-textarea>
              <VValidate name="Description (EN)" v-model="dataModalServiceProcessTitle.as_service_process_desc_en" :rules="mrValidationServiceProcessTitle.as_service_process_desc_en" />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_process_title')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceProcessTitle">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Process Title -->

      <!-- Modal Service Process -->
      <b-modal id="modal_service_process" no-close-on-backdrop title="Form Service Process">
        <validation-observer ref="VFormServiceProcess" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceProcess)">
            <b-form-group>
              <b-form-radio-group
                v-model="dataModalServiceProcess.is_svg"
                :options="optionsSVG"
                button-variant="outline-primary"
                buttons
              ></b-form-radio-group>
            </b-form-group>

            <template v-if="dataModalServiceProcess.is_svg == 'Y'">
              <b-form-group>
                <p class="text-center" v-html="dataModalServiceProcess.svg_tag"></p>

                <template #label>SVG Tag <span class="text-danger">*</span></template>
                <b-form-textarea :rows="4" v-model="dataModalServiceProcess.svg_tag"></b-form-textarea>
                <VValidate name="SVG Tag" v-model="dataModalServiceProcess.svg_tag" :rules="{ required: 1}" />
              </b-form-group>

              <b-form-group>
                <template #label>Color <span class="text-danger">*</span></template>
                <chrome-picker :value="dataModalServiceProcess.color" @input="updateColor($event, dataModalServiceProcess, 'color')" />
                <VValidate name="Color" v-model="dataModalServiceProcess.color" :rules="{ required: 1 }" />
              </b-form-group>
            </template>

            <b-form-group v-else>
              <template #label>Image <span class="text-danger">*</span></template>
              <Uploader v-model="dataModalServiceProcess.image" type="service_process" />
              <VValidate name="Image" v-model="dataModalServiceProcess.image" :rules="{ required: 1 }" />
            </b-form-group>

            <b-form-group>
              <template #label>Title (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceProcess.title_id" placeholder="e.g. Analisa"></b-form-input>
              <VValidate name="Title (ID)" v-model="dataModalServiceProcess.title_id" :rules="mrValidationServiceProcess.title_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Title (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceProcess.title_en" placeholder="e.g. Analysis"></b-form-input>
              <VValidate name="Title (EN)" v-model="dataModalServiceProcess.title_en" :rules="mrValidationServiceProcess.title_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceProcess.desc_id"></b-form-textarea>
              <VValidate name="Description (ID)" v-model="dataModalServiceProcess.desc_id" :rules="mrValidationServiceProcess.desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceProcess.desc_en"></b-form-textarea>
              <VValidate name="Description (EN)" v-model="dataModalServiceProcess.desc_en" :rules="mrValidationServiceProcess.desc_en" />
            </b-form-group>
            <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="$parent.Config.mr.StatusOptions"
                v-model="dataModalServiceProcess.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalServiceProcess.is_active" 
                :rules="mrValidationServiceProcess.is_active" 
              />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_process')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceProcess">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Process -->

      <!-- Modal Service Tech Title -->
      <b-modal id="modal_service_tech_title" no-close-on-backdrop title="Update Technology Stack">
        <validation-observer ref="VFormServiceTechTitle" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceTechTitle)">
            <b-form-group>
              <template #label>Name (ID) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceTechTitle.as_service_tech_name_id" placeholder="e.g. Teknologi yang Kami Gunakan"></b-form-input>
              <VValidate name="Name (ID)" v-model="dataModalServiceTechTitle.as_service_tech_name_id" :rules="mrValidationServiceTechTitle.as_service_tech_name_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Name (EN) <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceTechTitle.as_service_tech_name_en" placeholder="e.g. Technology We Work With"></b-form-input>
              <VValidate name="Name (EN)" v-model="dataModalServiceTechTitle.as_service_tech_name_en" :rules="mrValidationServiceTechTitle.as_service_tech_name_en" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (ID) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceTechTitle.as_service_tech_desc_id"></b-form-textarea>
              <VValidate name="Description (ID)" v-model="dataModalServiceTechTitle.as_service_tech_desc_id" :rules="mrValidationServiceTechTitle.as_service_tech_desc_id" />
            </b-form-group>
            <b-form-group>
              <template #label>Description (EN) <span class="text-danger">*</span></template>
              <b-form-textarea :rows="4" v-model="dataModalServiceTechTitle.as_service_tech_desc_en"></b-form-textarea>
              <VValidate name="Description (EN)" v-model="dataModalServiceTechTitle.as_service_tech_desc_en" :rules="mrValidationServiceTechTitle.as_service_tech_desc_en" />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_tech_title')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceTechTitle">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Tech Title -->

      <!-- Modal Service Tech -->
      <b-modal id="modal_service_tech" no-close-on-backdrop title="Form Service Tech">
        <validation-observer ref="VFormServiceTech" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitServiceTech)">
            <b-form-group>
              <template #label>Image <span class="text-danger">*</span></template>
              <Uploader v-model="dataModalServiceTech.image" type="tech_stack" />
              <VValidate name="Image" v-model="dataModalServiceTech.image" :rules="mrValidationServiceTech.image" />
            </b-form-group>
            <b-form-group>
              <template #label>Category <span class="text-danger">*</span></template>
              <b-input-group>
                <v-select name="category" v-model="dataModalServiceTech.category" :options="mrTechStackCategory" />
                <b-input-group-append>
                  <b-button type="button" @click="openModalAddNewCategory" variant="success" v-b-tooltip.hover.noninteractive="'Add New Category'"> <i class="fas fa-plus"></i> </b-button>
                </b-input-group-append>
              </b-input-group>
              <VValidate name="Title (ID)" v-model="dataModalServiceTech.category" :rules="mrValidationServiceTech.category" />
            </b-form-group>
            <b-form-group>
              <template #label>Name <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalServiceTech.name" placeholder="e.g. Flutter"></b-form-input>
              <VValidate name="Title (ID)" v-model="dataModalServiceTech.name" :rules="mrValidationServiceTech.name" />
            </b-form-group>
            <b-form-group>
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="$parent.Config.mr.StatusOptions"
                v-model="dataModalServiceTech.is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModalServiceTech.is_active" 
                :rules="mrValidationServiceTech.is_active" 
              />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_service_tech')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitServiceTech">Submit</b-button>
        </template>
      </b-modal>
      <!-- / Modal Service Process -->

      <b-modal id="modal_new_category" title="Add Technology Stack Category">
        <validation-observer ref="VFormNewCategory" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitNewCategory)">
            <b-form-group>
              <template #label>Category</template>
              <b-form-input v-model="dataModalCategory.category"></b-form-input>
              <VValidate name="Category" v-model="dataModalCategory.category" rules="required|min:3|max:60"/>
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_new_category')">Close</b-button>
          <b-button type="button" variant="primary" @click="submitNewCategory">Submit</b-button>
        </template>
      </b-modal>

    </b-tab>

    <b-tab title="SEO">
      <validation-observer ref="VFormSEO" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(doSubmitSEO)">
          <b-card no-body>
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.as_seo_title_id" />
                        <VValidate 
                          name="Meta Title ID" 
                          v-model="row.as_seo_title_id" 
                          :rules="{...mrValidationSEO.as_seo_title_id}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Canonical ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Canonical berfungsi untuk menentukan halaman versi utama jika memiliki halaman dengan konten yang duplikat atau serupa.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.as_seo_canonical_id" />
                        <VValidate 
                          name="Meta Canonical ID" 
                          v-model="row.as_seo_canonical_id" 
                          :rules="{...mrValidationSEO.as_seo_canonical_id}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description ID<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.as_seo_desc_id" />
                        <VValidate 
                          name="Meta Desc ID" 
                          v-model="row.as_seo_desc_id" 
                          :rules="{...mrValidationSEO.as_seo_desc_id}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords ID
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.as_seo_keyword_id" />
                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitleEN" placeholder="Meta Title" v-model="row.as_seo_title_en" />
                        <VValidate 
                          name="Meta Title EN" 
                          v-model="row.as_seo_title_en" 
                          :rules="{...mrValidationSEO.as_seo_title_en}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaCanonicalEN">
                        <template #label>
                          Meta Canonical EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Canonical berfungsi untuk menentukan halaman versi utama jika memiliki halaman dengan konten yang duplikat atau serupa.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaCanonicalEN" placeholder="Meta Title" v-model="row.as_seo_canonical_en" />
                        <VValidate 
                          name="Meta Canonical EN" 
                          v-model="row.as_seo_canonical_en" 
                          :rules="{...mrValidationSEO.as_seo_canonical_en}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaDescEN">
                        <template #label>
                          Meta Description EN<span class="text-danger">*</span>
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDescEN" placeholder="Meta Description" v-model="row.as_seo_desc_en" />
                        <VValidate 
                          name="Meta Desc EN" 
                          v-model="row.as_seo_desc_en" 
                          :rules="{...mrValidationSEO.as_seo_desc_en}" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label-for="homeMetaTagsEN">
                        <template #label>
                          Meta Keywords EN
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTagsEN" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.as_seo_keyword_en" />
                        
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-footer>
              <div class="text-right">
                <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-form>
      </validation-observer>
    </b-tab>
  </b-tabs>
</template>
<script>
import Gen from '@/libs/Gen.js'
const _ = global._

import { Chrome, Material } from 'vue-color'

export default {
  components: {
    'chrome-picker': Chrome,
    'material-picker': Material
  },
  props: {
    row: Object,
    mrValidation: Object,
    mrValidationHero: Object,
    mrValidationTitle: Object,
    mrValidationThumbnail: Object,
    mrValidationSubServiceTitle: Object,
    mrValidationSubService: Object,
    mrValidationServiceCollapseTitle: Object,
    mrValidationServiceCollapse: Object,
    mrValidationServiceProcessTitle: Object,
    mrValidationServiceProcess: Object,
    mrServiceProcessType: Array,
    mrValidationServiceTechTitle: Object,
    mrValidationServiceTech: Object,
    mrValidationSEO: Object,
    mrTechStackCategory: Array
  },
  data(){
    return {
      contentUpdating: {
        hero: false,
        title: false,
        thumbnail: false
      },
      dataModalHero: {},
      dataModalTitle: {},
      dataModalThumb: {},
      dataModalSubServiceTitle: {},
      dataModalSubService: {},
      dataModalServiceCollapseTitle: {},
      dataModalServiceCollapse:{},
      dataModalServiceProcessTitle: {},
      dataModalServiceProcess: {},
      dataModalServiceTech: {},
      dataModalServiceTechTitle: {},
      dataModalCategory: {},

      optionsSVG: [
        { text: 'Use Image', value: 'N' },
        { text: 'Use SVG', value: 'Y' },
      ]
    }
  },
  methods: {
    updateColor(colorValue, data, key){
      this.$set(data, key, colorValue.hex8)
    },
    showSection(key, value){
      console.log(key, value)

      this.$swal({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest("/do/BoServices", {
        data: {
          type: 'submit-section',
          section: 'show-section',
          as_id: this.row.as_id,
          [key]: value
        }
      }, "POST")
      .then(res => {
        this.$set(this.row, key, res.data.data[key])
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!!"
        })
      })
    },

    setUpdatingHero(bool){
      if(bool){
        const payload = {
          as_hero_title_id: this.row.as_hero_title_id,
          as_hero_title_en: this.row.as_hero_title_en,
          as_hero_desc_id: this.row.as_hero_desc_id,
          as_hero_desc_en: this.row.as_hero_desc_en,
          as_hero_image: this.row.as_hero_image,
          as_hero_gradient_left: this.row.as_hero_gradient_left,
          as_hero_gradient_right: this.row.as_hero_gradient_right,
        }
        this.dataModalHero = _.clone(payload)
      }else{
        this.dataModalHero = {}
      }
      this.contentUpdating.hero = bool
    },

    submitHero(){
      this.$refs.VFormHero.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'hero',
            as_id: this.row.as_id,
            ...this.dataModalHero
          }
        }, "POST").then(res => {
          this.updateContentHero(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.setUpdatingHero(false)
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    doSubmitSEO(){
      this.$refs.VFormSEO.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-seo',
            ...this.row
          }
        }, "POST").then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentHero(data){
      this.$set(this.row, 'as_hero_title_id', data.as_hero_title_id)
      this.$set(this.row, 'as_hero_title_en', data.as_hero_title_en)
      this.$set(this.row, 'as_hero_desc_id', data.as_hero_desc_id)
      this.$set(this.row, 'as_hero_desc_en', data.as_hero_desc_en)
      this.$set(this.row, 'as_hero_image', data.as_hero_image)
      this.$set(this.row, 'as_hero_gradient_left', data.as_hero_gradient_left)
      this.$set(this.row, 'as_hero_gradient_right', data.as_hero_gradient_right)
    },

    setUpdatingTitle(bool){
      if(bool){
        const payload = {
          as_title: this.row.as_title,
        }
        this.dataModalTitle = _.clone(payload)
      }else{
        this.dataModalTitle = {}
      }
      this.contentUpdating.title = bool
    },
    

    submitTitle(){
      this.$refs.VFormTitle.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-title',
            section: 'title',
            as_id: this.row.as_id,
            ...this.dataModalTitle
          }
        }, "POST").then(res => {
          this.updateContentTitle(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.setUpdatingTitle(false)
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentTitle(data){
      this.$set(this.row, 'as_title', data.as_title)
    },

    setUpdatingThumbnail(bool){
      if(bool){
        const payload = {
          as_title: this.row.as_title,
          as_thumb_desc_id: this.row.as_thumb_desc_id,
          as_thumb_desc_en: this.row.as_thumb_desc_en,
          as_thumb_file: this.row.as_thumb_file,
          as_thumb_bg_color: this.row.as_thumb_bg_color,
        }
        this.dataModalThumb = _.clone(payload)
      }else{
        this.dataModalThumb = {}
      }
      this.contentUpdating.thumbnail = bool
    },

    submitThumbnail(){
      this.$refs.VFormThumbnail.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'thumbnail',
            as_id: this.row.as_id,
            ...this.dataModalThumb
          }
        }, "POST").then(res => {
          this.updateContentThumbnail(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.setUpdatingThumbnail(false)
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentThumbnail(data){
      this.$set(this.row, 'as_thumb_file', data.as_thumb_file)
      this.$set(this.row, 'as_thumb_desc_id', data.as_thumb_desc_id)
      this.$set(this.row, 'as_thumb_desc_en', data.as_thumb_desc_en)
      this.$set(this.row, 'as_thumb_bg_color', data.as_thumb_bg_color)
    },

    closeModal(id){
      this.$bvModal.hide(id)
    },

    openModalSubTitle(){
        const payload = {
          as_sub_name_id: this.row.as_sub_name_id,
          as_sub_name_en: this.row.as_sub_name_en,
          as_sub_desc_id: this.row.as_sub_desc_id,
          as_sub_desc_en: this.row.as_sub_desc_en,
        }

        this.dataModalSubServiceTitle = _.clone(payload)
        this.$bvModal.show('modal_sub_service_title')
    },
    submitSubServiceTitle(){
      this.$refs.VFormSubServiceTitle.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'sub-service-title',
            as_id: this.row.as_id,
            ...this.dataModalSubServiceTitle
          }
        }, "POST").then(res => {
          this.updateContentSubServiceTitle(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_sub_service_title')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentSubServiceTitle(data){
      this.$set(this.row, 'as_sub_name_id', data.as_sub_name_id)
      this.$set(this.row, 'as_sub_name_en', data.as_sub_name_en)
      this.$set(this.row, 'as_sub_desc_id', data.as_sub_desc_id)
      this.$set(this.row, 'as_sub_desc_en', data.as_sub_desc_en)
    },

    openModalSubService(value){
      let payload = value

      this.dataModalSubService = _.clone({ ...payload, color: payload.color || "#FFFFFF", is_svg: payload.is_svg || 'N' })
      this.$bvModal.show('modal_sub_service')
    },

    submitSubService(){
      this.$refs.VFormSubService.validate().then(success => {
        if(!success) return

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-sub-service',
            as_id: this.row.as_id,
            ...this.dataModalSubService
          }
        }).then(res => {
          this.$set(this.row, 'as_sub_content', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_sub_service')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!!"
          })
        })
      })
    },

    openModalServiceCollapseTitle(){
      const payload = {
          as_service_col_name_id: this.row.as_service_col_name_id,
          as_service_col_name_en: this.row.as_service_col_name_en,
          as_service_col_desc_id: this.row.as_service_col_desc_id,
          as_service_col_desc_en: this.row.as_service_col_desc_en,
        }

        this.dataModalServiceCollapseTitle = _.clone(payload)
        this.$bvModal.show('modal_service_collapse_title')
    },

    submitServiceCollapseTitle(){
      this.$refs.VFormServiceCollapseTitle.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'service-collapse-title',
            as_id: this.row.as_id,
            ...this.dataModalServiceCollapseTitle
          }
        }, "POST").then(res => {
          this.updateContentServiceCollapseTitle(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_collapse_title')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentServiceCollapseTitle(data){
      this.$set(this.row, 'as_service_col_name_id', data.as_service_col_name_id)
      this.$set(this.row, 'as_service_col_name_en', data.as_service_col_name_en)
      this.$set(this.row, 'as_service_col_desc_id', data.as_service_col_desc_id)
      this.$set(this.row, 'as_service_col_desc_en', data.as_service_col_desc_en)
    },

    openModalServiceCollapse(value){
      let payload = value

      this.dataModalServiceCollapse = _.clone(payload)
      this.$bvModal.show('modal_service_collapse')
    },

    submitServiceCollapse(){
      this.$refs.VFormServiceCollapse.validate().then(success => {
        if(!success) return

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-service-collapse',
            as_id: this.row.as_id,
            ...this.dataModalServiceCollapse
          }
        }).then(res => {
          this.$set(this.row, 'as_service_col_content', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_collapse')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!!"
          })
        })
      })
    },

    openModalServiceProcessTitle(){
      const payload = {
          as_service_process_name_id: this.row.as_service_process_name_id,
          as_service_process_name_en: this.row.as_service_process_name_en,
          as_service_process_desc_id: this.row.as_service_process_desc_id,
          as_service_process_desc_en: this.row.as_service_process_desc_en,
        }

        this.dataModalServiceProcessTitle = _.clone(payload)
        this.$bvModal.show('modal_service_process_title')
    },

    submitServiceProcessTitle(){
      this.$refs.VFormServiceProcessTitle.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'service-process-title',
            as_id: this.row.as_id,
            ...this.dataModalServiceProcessTitle
          }
        }, "POST").then(res => {
          this.updateContentServiceProcessTitle(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_process_title')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentServiceProcessTitle(data){
      this.$set(this.row, 'as_service_process_name_id', data.as_service_process_name_id)
      this.$set(this.row, 'as_service_process_name_en', data.as_service_process_name_en)
      this.$set(this.row, 'as_service_process_desc_id', data.as_service_process_desc_id)
      this.$set(this.row, 'as_service_process_desc_en', data.as_service_process_desc_en)
    },

    openModalServiceProcess(value){
      let payload = value

      this.dataModalServiceProcess = _.clone({ ...payload, color: payload.color || "#FFFFFF", is_svg: payload.is_svg || 'N' })
      this.$bvModal.show('modal_service_process')
    },

    submitServiceProcess(){
      this.$refs.VFormServiceProcess.validate().then(success => {
        if(!success) return

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-service-process',
            as_id: this.row.as_id,
            ...this.dataModalServiceProcess
          }
        }).then(res => {
          this.$set(this.row, 'as_service_process_content', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_process')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!!"
          })
        })
      })
    },
    
    openModalServiceTechTitle(){
      const payload = {
          as_service_tech_name_id: this.row.as_service_tech_name_id,
          as_service_tech_name_en: this.row.as_service_tech_name_en,
          as_service_tech_desc_id: this.row.as_service_tech_desc_id,
          as_service_tech_desc_en: this.row.as_service_tech_desc_en,
        }

        this.dataModalServiceTechTitle = _.clone(payload)
        this.$bvModal.show('modal_service_tech_title')
    },

    submitServiceTechTitle(){
      this.$refs.VFormServiceTechTitle.validate().then(success => {
        if(!success) return 

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-section',
            section: 'service-tech-title',
            as_id: this.row.as_id,
            ...this.dataModalServiceTechTitle
          }
        }, "POST").then(res => {
          this.updateContentServiceTechTitle(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_tech_title')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!!'
          })
        })
      })
    },

    updateContentServiceTechTitle(data){
      this.$set(this.row, 'as_service_tech_name_id', data.as_service_tech_name_id)
      this.$set(this.row, 'as_service_tech_name_en', data.as_service_tech_name_en)
      this.$set(this.row, 'as_service_tech_desc_id', data.as_service_tech_desc_id)
      this.$set(this.row, 'as_service_tech_desc_en', data.as_service_tech_desc_en)
    },

    openModalServiceTech(value){
      let payload = value

      this.dataModalServiceTech = _.clone(payload)
      this.$bvModal.show('modal_service_tech')
    },

    submitServiceTech(){
      this.$refs.VFormServiceTech.validate().then(success => {
        if(!success) return

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-service-tech',
            as_id: this.row.as_id,
            ...this.dataModalServiceTech
          }
        }).then(res => {
          this.$set(this.row, 'as_service_tech_content', res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.closeModal('modal_service_tech')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!!"
          })
        })
      })
    },

    openModalAddNewCategory(){
      this.$set(this, 'dataModalCategory', {})
      this.$bvModal.show('modal_new_category')
    },

    submitNewCategory(){
      this.$refs.VFormNewCategory.validate().then(result => {
        if(!result) return

        this.$parent.swalLoading()

        Gen.apiRest("/do/BoServices", {
          data: {
            type: 'submit-category',
            ...this.dataModalCategory
          }
        }).then(res => {
          this.$set(this, 'mrTechStackCategory', res.data.data)
          this.$bvModal.hide('modal_new_category')
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!'
          })
        })
      })
    },

    doDelete(column, id){
      this.$parent.swalDelete()
      .then(result => {
        if(result.value){
          this.$parent.swalLoading()

          Gen.apiRest("/do/BoServices", {
            data: {
              type: 'delete-list-content',
              column: column,
              id: id,
              as_id: this.row.as_id
            }
          }).then(res => {
            this.$set(this.row, column, res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    },

    techContent(category){
      if(!(this.row.as_service_tech_content||[]).length) return []
      return this.row.as_service_tech_content.filter(tech => tech.category == category)
    }

  },
  destroyed(){
    this.contentUpdating = {
        hero: false,
        title: false,
        thumbnail: false
      }

      this.dataModalHero =  {}
      this.dataModalTitle =  {}
      this.dataModalThumb =  {}
      this.dataModalSubServiceTitle =  {}
      this.dataModalSubService =  {}
      this.dataModalServiceCollapseTitle =  {}
      this.dataModalServiceCollapse = {}
      this.dataModalServiceProcessTitle =  {}
      this.dataModalServiceProcess =  {}
      this.dataModalServiceTech =  {}
      this.dataModalServiceTechTitle = {}
      this.dataModalCategory = {}
  },
  computed: {
    categoryService(){
      if(!(this.row.as_service_tech_content||[]).length) return []

      return [ ...new Set(this.row.as_service_tech_content.map(tech => tech.category)) ]
    }
  }
}
</script>
