<template>
  <b-container fluid>
    <bo-page-title>
      <template #additionalCta>
        <b-button
          v-if="isList && moduleRole('add')"
          variant="success"
          class="m-l-15 btn-rounded"
          @click="submit"
        >
          <i class="fa fa-plus-circle mr-2" />
          Add {{ objName }}
        </b-button>
        <b-button
          v-else
          variant="success"
          class="m-l-15 btn-rounded"
          :to="{name: 'BoServices'}"
        >
          <i class="fas fa-list mr-2" />
          {{ objName || pageTitle || defPageTitle }} List
        </b-button>
      </template>
    </bo-page-title>

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ pageTitle }} List </h5>
          </b-col>
          <b-col lg="4">
            <SearchInput :value.sync="filter.search" @search="doFill" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row class="gutter-2">
          <b-col v-if="loading" lg="12" class="text-center">
            <h4>Loading...</h4>
          </b-col>
          <template v-else-if="(data.data || []).length">
            <b-col lg="12" v-for="(value, index) in data.data" :key="index">
              <article :class="['service_list', 'bg_service_' + (index + 1)]">
                <div class="bulle_cta">
                  <b-button variant="secondary" v-if="moduleRole('edit')" v-b-tooltip.hover.noninteractive="'Edit'" class="btn-icon" pill :to="{name: 'BoServices', params: {pageSlug: value.as_id}}">
                    <i class="fas fa-pencil-alt" />
                  </b-button>
                  <b-button variant="danger" v-if="moduleRole('delete')" v-b-tooltip.hover.noninteractive="'Delete'" class="btn-icon" pill @click="deleteService(value.as_id)">
                    <i class="far fa-trash-alt" />
                  </b-button>
                  <b-button variant="warning" v-if="moduleRole('edit')" v-b-tooltip.hover.noninteractive="'Change Status'" class="btn-icon" pill @click="doChangeStatus(index, value)">
                    <i class="ti-settings" />
                  </b-button>
                </div>
                <b-row class="gutter-2">
                  <b-col lg="3">
                    <img :src="uploader(value.as_hero_image)" />
                    <p class="text-center">
                      <b-badge variant="success" v-if="value.as_is_active == 'Y'">Active</b-badge>
                      <b-badge variant="danger" v-else>Inactive</b-badge>
                    </p>
                  </b-col>
                  <b-col lg="8">
                    <h2>{{value.as_title}}</h2>
                    <p>{{ value.as_hero_desc_id }}</p>
                    <ul>
                      <li v-for="(value2, index2) in value.as_sub_content" :key="index + '' + index2">{{value2.name_id}}</li>
                    </ul>
                  </b-col>
                </b-row>
              </article>
            </b-col>
          </template>
          <template v-else>
            <b-col lg="12" class="text-center">
              <h4>Services not available</h4>
            </b-col>
          </template>
        </b-row>
      </b-card-body>
      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
        <b-pagination
        class="mb-0"
        v-model="pageNo"
        :per-page="data.per_page"
        :total-rows="data.total"
        />
      </b-card-footer>
    </b-card>
    <template v-else>
      <Form :row.sync="row" :mrTechStackCategory.sync="mrTechStackCategory" v-bind="passToSub" />
    </template>

    <b-modal id="modal_form" :title="(dataModal.mc_id ? 'Update' : 'Add') + ' Service'" no-close-on-backdrop> 
      <validation-observer ref="VForm" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submit)">
          <b-form-group>
            <template #label>Service Title <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModal.as_title" placeholder="e.g. Software Development"></b-form-input>
            <VValidate name="Title" v-model="dataModal.as_title" :rules="mrValidation.as_title" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submit">Submit</b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  components: {Form},
  extends: GlobalVue,
  data() {
    return {
      idKey:'as_id',
      statusKey:'as_is_active',
      mrLevel:[],
      loading: false,
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mt_name',
          label: 'Full Name',
        },
        
        {
          key: 'mt_description_id',
          label: 'Testimonial',
        },
        {
          key: 'mt_is_active',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
      dataModal: {},
      mrValidationHero: {},
      mrValidationTitle: {},
      mrValidationThumbnail: {},
      mrValidationSubServiceTitle: {},
      mrValidationSubService: {},
      mrValidationServiceCollapseTitle: {},
      mrValidationServiceCollapse: {},
      mrValidationServiceProcessTitle: {},
      mrValidationServiceProcess: {},
      mrServiceProcessType: [],
      mrValidationServiceTechTitle: {},
      mrValidationServiceTech: {},
      mrValidationSEO: {},
      mrTechStackCategory: []
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{}, {
        mrValidationHero:this.mrValidationHero,
        mrValidationTitle:this.mrValidationTitle,
        mrValidationThumbnail: this.mrValidationThumbnail,
        mrValidationSubServiceTitle: this.mrValidationSubServiceTitle,
        mrValidationSubService: this.mrValidationSubService,
        mrValidationServiceCollapseTitle: this.mrValidationServiceCollapseTitle,
        mrValidationServiceCollapse: this.mrValidationServiceCollapse,
        mrValidationServiceProcessTitle: this.mrValidationServiceProcessTitle,
        mrValidationServiceProcess: this.mrValidationServiceProcess,
        mrServiceProcessType: this.mrServiceProcessType,
        mrValidationServiceTech: this.mrValidationServiceTech,
        mrValidationServiceTechTitle: this.mrValidationServiceTechTitle,
        mrValidationSEO: this.mrValidationSEO,
      })
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    apiGet(params = {}){
      this.loading = true
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      Gen.apiRest(
        "/get/"+this.modulePage + slugs, 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      }).catch(err=>{
        this.loading = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },
    doFill(){
      this.doFilter()
    },
    openModalAdd(){
      this.$set(this, 'dataModal', {
        mt_is_active: 'Y'
      })
      this.$bvModal.show('modal_form')
    },
    closeModal(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('modal_form')
    },
    submit(){
      // this.$refs.VForm.validate().then(success => {
      //   if(!success) return
      // })

      this.swalLoading()

      Gen.apiRest("/do/" + this.modulePage, {
        data: {
          type: 'add',
          ...this.dataModal
        }
      }).then(res => {
        this.$swal.close()
        this.closeModal()
        this.$router.push({name: 'BoServices', params: {pageSlug: res.data.data.as_id}})
      }).catch(err => {
        this.$swal({
          icon: 'error',
          title: err.response.data.message || 'Terjadi Suatu Kesalahan!',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Make a new service",
          confirmButtonText: "Continue to fill existing service"          
        }).then(button => {
          if(button.isConfirmed){
            const dataError = err.response.data.data
            this.$router.push({ name: 'BoServices', params: { pageSlug: dataError.id } })
          } else {
            this.swalLoading()
            
            Gen.apiRest("/do/" + this.modulePage, {
              data: {
                type: 'add',
                force: 'yes',
                ...this.dataModal
              }
            }).then(resNew => {
              this.$swal.close()
              this.closeModal()
              this.$router.push({name: 'BoServices', params: {pageSlug: resNew.data.data.as_id}})
            })
          }
        })
      })
    },
    deleteService(id){
      this.swalDelete().then(result => {
        if(result.value){
          this.swalLoading()

          Gen.apiRest("/do/BoServices", {
            data: {
              type: 'delete-service',
              as_id: id
            }
          }).then(res => {
            this.$swal({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              this.apiGet()
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              message: err.response.data.message || "Something went wrong"
            })
          })
        }
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>